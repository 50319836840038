import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"

const ContactForm = () => (
  <Form>
    <form
      method="post"
      name="contact"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      action="/success"
      onSubmit={() => {
        if (typeof window !== "undefined" && window.dataLayer !== undefined) {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "form_submit",
          })
        }
      }}
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <section>
        <label htmlFor="name">Nome e cognome</label>
        <input type="text" name="name" id="name" required />
      </section>
      <section>
        <label htmlFor="email">Email</label>
        <input type="text" name="email" id="email" required />
      </section>
      <section>
        <label htmlFor="phone">Numero di telefono</label>
        <input type="tel" name="phone" id="phone" required />
      </section>
      <section>
        <label htmlFor="company">Azienda</label>
        <input type="text" name="company" id="company" required />
      </section>
      <section>
        <label htmlFor="message">Messaggio</label>
        <textarea name="message" id="message" rows="6" required />
      </section>
      <section className="checkbox">
        <input type="checkbox" id="privacy" name="privacy" required />
        <label htmlFor="privacy">
          Dichiaro di aver preso visione dell'
          <a href="/informativa-privacy">informativa privacy</a>
        </label>
      </section>
      <section>
        <input type="submit" value="Invia messaggio" />
      </section>
    </form>
  </Form>
)

export default ContactForm

const Form = styled.div`
  form {
    label {
      margin-bottom: ${DesignTokens.spacing[2]};
      text-transform: uppercase;
    }
    input,
    textarea {
      padding: ${DesignTokens.spacing[4]};
      border: none;
      border-bottom: 1px solid ${DesignTokens.colors.tertiary[200]};
      &:hover,
      &:focus {
        border-bottom: 1px solid ${DesignTokens.colors.primary[500]};
      }
    }

    input[type="submit"] {
      background: ${DesignTokens.colors.primary[500]};
      border: none;
      color: ${DesignTokens.colors.white};
      border-radius: ${DesignTokens.borderRadius};
      transition: all 0.2s ease-in;
      &:hover {
        cursor: pointer;
        background: ${DesignTokens.colors.primary[400]};
      }
      &:active {
        background: ${DesignTokens.colors.primary[400]};
      }
    }
    section {
      display: flex;
      flex-direction: column;
    }
    section.checkbox {
      flex-direction: row;
    }
    section.checkbox label {
      margin-bottom: 0;
      margin-left: 8px;
    }
    & > * {
      margin-bottom: ${DesignTokens.spacing[7]};
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`
